/* eslint-disable @next/next/no-img-element */
// import { useRouter } from 'next/router';
import Image from 'next/image';
import titleBg from '@public/imgs/trail-course/title-bg.png';
import expectationsGS2Avtar1 from '@public/imgs/subscriptionDetails/teacher-1.png';
import expectationsGS2Avtar2 from '@public/imgs/subscriptionDetails/teacher-2.png';
import expectationsGS2Avtar3 from '@public/imgs/subscriptionDetails/teacher-3.png';
import expectationsGS2Avtar4 from '@public/imgs/subscriptionDetails/teacher-4.png';
import { useMedia } from 'react-use';
import { goGs2Booking } from '@/utils/gs2';
import { IExpectationsGS2Teacher } from '@/pageComponents/Home/interface';
import style from './style.module.scss';
import { traceEvent } from '@wk/wk-gatherer';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { avatorDefault } from '@/pageComponents/Home/TrialCourse/constants';
import classNames from 'classnames';

export interface ExpectationsGS2Props {
  teacher?: IExpectationsGS2Teacher;
  subject?: string;
  teachersHead?: string[];
}
const ExpectationsGS2: React.FC<ExpectationsGS2Props> = (props) => {
  const { teacher, subject, teachersHead } = props;
  const isMobile = useMedia('(max-width: 720px)');
  const { t, i18n } = useTranslation(['home', 'appointment']);
  const isZh = i18n.language === 'zh';

  useEffect(() => {
    handleReport('U_HP_TrialEnterExpose');
  }, []);

  const expectationList = (teachersHead && teachersHead.length > 0 && teachersHead) || [
    expectationsGS2Avtar1.src,
    expectationsGS2Avtar2.src,
    expectationsGS2Avtar3.src,
    expectationsGS2Avtar4.src,
  ];
  // 老师列表=5; 老师详情=6;
  const handleBooking = (targetStep: '5' | '6', eventName: string) => {
    const teacherId = teacher?.uuid || '';
    const source = isMobile ? 'stu_mobile' : 'stu_PC';
    goGs2Booking({ source, targetStep, teacherId, trigger_name: eventName });
  };
  const handleReport = (_event: string, data?: { [key: string]: any }) => {
    const defaultData = {
      // 当前模块只能是 GS2
      studentType: 'GS2.0',
      teacherId: teacher?.uuid,
      subject,
      // 当前模块只有未约课才展示
      courseStatus: '体验课未约课',
    };
    traceEvent({
      data: { ...defaultData, ...data, _event },
    });
  };

  const handleMore = () => {
    handleReport('U_HP_TrialEnter_ExploreClick');
    handleBooking('5', 'U_HP_TrialEnter_ExploreClick');
  };

  const handleTeacherBooking = () => {
    handleReport('U_HP_TrialEnter_BookNowClick');
    handleBooking('6', 'U_HP_TrialEnter_BookNowClick');
  };

  return (
    <div className={classNames(style.container, 'mobile:bg-white mobile:w-[calc(100vw-32px)] mobile: mx-auto rounded-xl')}>
      {/* 头部 */}
      <div className="relative mt-6 overflow-hidden rounded-t-xl bg-[#4C99DE] px-4 pb-[26px] pt-4 text-2xl font-extrabold  text-white md:px-10 md:pb-[34px] md:pt-6 lg:first:mt-0">
        <span className="hidden text-white md:block">{t('GS2订阅入口-标题')}</span>
        <span className="text-white md:hidden">{t('GS2订阅入口-标题短')}</span>
        <div className="absolute right-[87px] top-0 hidden h-[82px] w-[152px] md:block">
          <Image src={titleBg} width={152} height={82} />
        </div>
      </div>
      {/* 内容 */}
      <div className="relative -mt-2.5 flex flex-col rounded-xl bg-white px-4 pb-6 pt-5 text-wk-words-1 md:flex-row md:px-10 md:py-8 md:shadow-none">
        <div className="flex-1 border-b border-solid border-wk-border-1 pb-6 md:rounded-xl md:border md:p-6">
          <div className="flex">
            <div className="mr-3 h-10 w-10 rounded border border-solid border-wk-border-1  md:mr-4 md:h-[60px] md:w-[60px]">
              <img className="h-full w-full rounded" src={teacher?.imageUrl || avatorDefault.src} alt="avator" />
            </div>
            <div className="flex-1">
              <span className="text-sm text-wk-words-2 md:text-base">
                {(isZh ? teacher?.fullName : teacher?.gsName) || teacher?.fullName}
              </span>
              <p className="mt-2 rounded-bl-3xl rounded-br-3xl rounded-tr-3xl bg-wk-bg-1 p-4 text-base md:mt-3 md:p-6">
                {t('GS2订阅入口-老师邀请')}
              </p>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={handleTeacherBooking}
              className="  mt-6 h-[42px] cursor-pointer rounded-full border-none bg-wk-chinese px-11 text-base font-semibold leading-[42px]  text-white hover:bg-[#DB0202] md:px-8 md:text-sm "
            >
              {t('GS2订阅入口-立即预约')}
            </button>
          </div>
        </div>
        <div className="box-border pt-6 text-center md:ml-5 md:w-[343px] md:rounded-xl md:border md:border-solid md:border-wk-border-1 md:p-6">
          <div className=" relative ml-8 flex h-[90px] justify-center">
            {expectationList.map((item, index) => (
              <div
                key={index}
                className="-ml-8 box-border h-[90px] w-[90px] rounded-lg border-2 border-solid border-white"
                style={{ zIndex: expectationList.length - index }} // Adjust the zIndex here
              >
                <img className="h-full w-full rounded-lg" src={item} alt="avatar" />
              </div>
            ))}
          </div>
          <p className="mt-3  text-base font-medium">{t('GS2订阅入口-合适老师')}</p>
          <button className="mx-auto mt-6 flex h-[42px] cursor-pointer items-center justify-center rounded-full  border border-solid border-wk-border-1  bg-white px-8 text-center text-base font-semibold leading-[42px] hover:bg-[#0A0A0A] hover:text-white  md:mt-5 md:px-6 md:text-sm ">
            <span className="" onClick={handleMore}>
              {t('GS2订阅入口-更多老师')}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpectationsGS2;
