export const DEFAULT_ENTER_CLASS_SECOND = 11 * 60;

export const DEFAULT_ENTER_TALK_CLOUD_CLASS_SECOND = 20 * 60;
// NORMAL("待开课"),
// COMPLETED("已完成"),
// CANCELLED("已取消"),
// LEAVE_APPLIED("已提交请假申请"),
// ABSENT("旷课");
export const STUDENT_COURSE_NORMAL = 'NORMAL';
export const STUDENT_COURSE_COMPLETED = 'COMPLETED';
export const STUDENT_COURSE_CANCELLED = 'CANCELLED';
export const STUDENT_COURSE_ABSENT = 'ABSENT';

// 体验课步骤
export const CLASS_REPORT = 'classReport';
export const HOMEWORK = 'homework';
export const PREPARE = 'Prepare';

// 任务步骤的具体状态
export const TASK_NORMAL = 'NORMAL';
export const TASK_PROCESSING = 'PROCESSING';
export const TASK_COMPLETED = 'COMPLETED';

import titleBgIcon from '@public/imgs/trail-course/title-bg.png';
import avatorDefault from '@public/imgs/default-teacher.svg';
import classTypeIcon from '@public/imgs/trail-course/class-type.png';
import durationIcon from '@public/imgs/trail-course/duration.png';
import nameIcon from '@public/imgs/trail-course/name.png';
import timeIcon from '@public/imgs/trail-course/time.png';
import applaudIcon from '@public/imgs/trail-course/applaud.png';
import moreIcon from '@public/imgs/trail-course/more.png';

export { titleBgIcon, avatorDefault, classTypeIcon, durationIcon, nameIcon, timeIcon, moreIcon, applaudIcon };

// 体验课卡片埋点
export const TRIAL_CARD_EXPOSE = 'U_HP_TrialCardExpose';
export const TRIAL_CARD_JOIN_CLICK = 'U_HP_TrialCard_JoinClick';
export const TRIAL_CARD_TO_PREPARATION_CLICK = 'U_HP_TrialCard_ToPreparationClick';
export const TRIAL_CARD_TO_PREVIEW_CLICK = 'U_HP_TrialCard_ToPreviewClick';
export const TRIAL_CARD_TO_HOMEWORK_CLICK = 'U_HP_TrialCard_ToHomeworkClick';
export const TRIAL_CARD_RESCHEDULE_CLICK = 'U_HP_TrialCard_ReScheduleClick';
export const TRIAL_CARD_VIEW_REPORT_CLICK = 'U_HP_TrialCard_ViewReportClick';
export const TRIAL_CARD_TO_MORE_CLICK = 'U_HP_TrialCard_MoreClick';
export const TRIAL_CARD_MORE_SUPERVISE_CLICK = 'U_HP_TrialCard_More_SuperviseClick';
export const TRIAL_CARD_MORE_CANCEL_CLASS_CLICK = 'U_HP_TrialCard_More_CancelClassClick';
