import { useEffect, useState } from 'react';

/**
 *
 * @param countDownTime 倒计时差值(秒)
 * @returns [差值, 小时, 分钟, 秒]
 */
const useCountDown = (countDownTime: number): [number, number, number, number] => {
  const [time, setTime] = useState(countDownTime);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);

  useEffect(() => {
    let timer = -1;
    if (time > 0) {
      timer = window.setTimeout(() => {
        setTime(time - 1);
      }, 1e3);
      setHour(Math.floor(time / 3600));
      setMinute(Math.floor((time / 60) % 60));
      setSecond(Math.floor(time % 60));
    }
    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  return [time, hour, minute, second];
};

export default useCountDown;
