import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'next-i18next';
import Collapse from '../Collapse';
import StepNumber from '../StepNumber';
import { TASK_COMPLETED, TASK_NORMAL, TASK_PROCESSING } from '../constants';
import styles from './style.module.scss';
import { IMemoReportProps, ITaskState } from '../interface';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import Step from './components/Step';

type IProps = {
  course?: Partial<ICourseInfo>;
  step: number;
};

export const CLASS_REPORT: string = 'classReport';
const ClassReport = ({ course, step, reportList, memorizedSetReport }: IProps & IMemoReportProps) => {
  const { t } = useTranslation(['trailCourse']);
  const { crmReport, courseScheduleId } = course || {};
  const isReaded = !!reportList?.[CLASS_REPORT]?.includes(courseScheduleId as string);
  const hasReport = !!crmReport?.trialReportUrl;
  const [taskState, setTaskState] = useState<ITaskState>(TASK_NORMAL);

  const handleExpand = (expand: boolean) => {
    if (hasReport && isReaded) {
      // 如果读过报告, 一直为绿色, 不分展开收起
      setTaskState(TASK_COMPLETED);
    } else {
      // 未读过报告: 展开: 粉色 收起: 灰色
      setTaskState(expand ? TASK_PROCESSING : TASK_NORMAL);
    }
  };

  useEffect(() => {
    setTaskState(!!(hasReport && isReaded) ? TASK_COMPLETED : TASK_NORMAL);
  }, [hasReport, isReaded]);
  return (
    <>
      <StepNumber taskState={taskState} step={step} isPlot={hasReport && !isReaded} />
      <Collapse
        defaultExpand={false}
        title={<span className={styles.stepBarTitle}>{t('查看体验课报告')}</span>}
        onCollapse={handleExpand}
      >
        <Step {...course} memorizedSetReport={memorizedSetReport} key={CLASS_REPORT} isReaded={isReaded} />
      </Collapse>
    </>
  );
};

export default ClassReport;
